import React , {useState,useRef} from 'react'
import {
    ContactDescription,
    ContanctForm,
    ContactInput,
    ContactText,
    ContactNotification,
    ContactLoading,
    ContactSubmit
} from './ContactElements'
import { texts } from '../../texts'
import { colors } from '../../theme' 
import { GlobalContext } from "../GlobalContext"
import emailjs from 'emailjs-com';
export default function Contact() {
    const { language } = React.useContext(GlobalContext)

    const [subject,setSubject] = useState('')
    const [email,setEmail] = useState('')
    const [name,setName] = useState('')
    const [text,setText] = useState('')
    const [show,setShow] = useState('')
    const [loading,setLoading] = useState(false)
  

    // sendFeedback (templateId, variables) {
    //     console.log('feedback sent')
    //   window.emailjs.send(
    //     'service_vjjiasf', templateId,
    //     variables
    //     ).then(res => {
    //       this.setState({show:true})
    //       setTimeout(() => {
    //           this.setState({show:false})
    //       }, 1000)
          
    //     })
    //     // Handle errors here however you like, or use a React error boundary
    //     .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    // }
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      setLoading(true)  
      emailjs.sendForm('service_se4lqe9', 'template_u4ent2a', form.current, 'user_fY4m5tmDh5Y0gf9qWhTwO')
        .then((result) => {
            setSubject('')
            setEmail('')
            setName('')
            setText('')
            setShow(texts.contact.notification.success[language])
            setLoading(false)  
            setTimeout(() => {
                setShow('')
            }, 3000)
        }, (error) => {
            setShow(texts.contact.notification.warn[language])
            setSubject('')
            setEmail('')
            setName('')
            setText('')
            setLoading(false)  
            setTimeout(() => {
                setShow('')
            }, 2000)
        });
    };
    return (
        <div>
            <ContactDescription>
               <p>{texts.contact.intro[language]}</p> 
            </ContactDescription>            
            <ContanctForm ref={form} onSubmit={(e) => sendEmail(e)} >
                <ContactInput name='form_subject' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder={texts.contact.form.subject[language]} required/>
                <ContactInput type='email' name='form_email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder={texts.contact.form.email[language]} required/>
                <ContactInput name='form_name' value={name} onChange={(e) => setName(e.target.value)} placeholder={texts.contact.form.name[language] +'*'} required/>
                <ContactText name='form_text' value={text} onChange={(e) => setText(e.target.value)} placeholder={texts.contact.form.text} required/>
                <ContactSubmit bg={colors.green} type='submit'  >
                    <p>{texts.contact.button[language]}</p>
                    {loading &&<div><ContactLoading /></div>}
                </ContactSubmit>
            </ContanctForm>
            {
            show !== '' &&
            <ContactNotification>
                {show}
            </ContactNotification>
            }
        </div>
    )
}
