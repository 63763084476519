import styled , {keyframes} from "styled-components";
import { colors } from "../../theme";

export const ContactDescription  = styled.div`
    display: flex;
    flex: 1;
    padding: 40px 0px;
    align-items: center;
    justify-content: center;
    align-items: center
    p{
        font-weight: bolder;
        display: flex;
    };
`

export const ContanctForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align:center;
    button{
        color: white;
        font-weight: 700;
        padding: 20px 50px;
        margin-top: 0px;
    }
`

export const ContactInput = styled.input`

    box-shadow: 0px 0px 10px #888 !important;
    margin:30px auto;
    display: flex;
    width: 80vw;
    max-width: 800px;
    min-width: 200px;
    justify-content: flex-start;
    align-items: center;
    padding:20px 30px;
`


export const ContactText = styled.textarea`
    width: 80vw;
    max-width: 800px;
    min-width: 200px;
    height: 300px;
    box-shadow: 0px 0px 10px #888 !important;
    margin:40px auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:20px 30px;
`

const fade = keyframes`
    0%{
        opacity: 1;
        transform: translateY(0px);
        display: flex;

    }

    50%{
        opacity: .5;
        transform: translateY(15px);
        display: flex;

    }

    100%{
        opacity: 0;
        transform: translateY(25px);
        display: none;
    }

`

export const ContactNotification = styled.p`
    position: fixed;
    bottom: 5vh;
    right:5vw;
    background-color: ${colors.dark2};
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    animation: ${fade} 3s linear ;
    z-index: 1000;
    padding: 5px 30px;
    border-radius: 10px;
    color: white;
    overflow: hidden;
`

const load = keyframes`
    0%{
        width:5px;
        height: 5px;
    }

    40%{
        width:15px;
        height: 15px;
    }

    100%{
        width:5px;
        height: 5px;
    }

`

export const ContactLoading = styled.div`
    width:5px;
    height: 5px;
    border-radius: 50%;
    background-color: white;
    animation: ${load} .5s ease-in infinite;
    display: flex;
    margin: 10px;
`

export const ContactSubmit = styled.button`
    height: 60px;
    width: 150px;
    background-color: ${colors.green};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px !important;
    border-radius: 10px;
   div{
       width: 30px;
       height: 30px;
       display: flex;
       justify-content: center;
       align-items: center;
   }
`