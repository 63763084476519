import React  from "react"
import '../styles/global.css'
import {
    AboutStyle,
    AboutContainer,
    Title,
    PointerSvg
} from '../components/About/AboutElements'
import Contact from "../components/Contact" 
import { texts } from "../texts"
import { GlobalContext } from "../components/GlobalContext"
import Layout from "../layout"

const ContactPage = () => {
    const { language } = React.useContext(GlobalContext)
  
  return (
        <>
            <AboutStyle style={{marginTop:100}}>
                      <AboutContainer>
                          <Title>
                                  <PointerSvg/>
                                  <h1 style={{marginLeft:10}}>{texts.contact.title[language]}</h1>
                          </Title>
                          <Contact />
                      </AboutContainer>
            </AboutStyle>
        </>
  )
}

ContactPage.Layout = Layout
export default ContactPage
